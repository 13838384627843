Core.FlexFill = Garnish.Base.extend(
{
    $elem: $('.flex-fill'),

    init: function(settings)
    {
        Core.debug && console.log('Core.FlexFill.init() invoked');

        if (! this.$elem[0])
        {
            return;
        }

        this.setSettings(settings, Core.FlexFill.defaults);
        this.addListener(Garnish.$scrollContainer, 'resize', 'resize');

        Garnish.$scrollContainer.trigger('resize');
    },

    resize: function(e)
    {
        var self = this;
        setTimeout(function()
        {
            var $this = $(e.currentTarget);

            self.$elem.removeAttr('style');

            if ($this.width() < 768)
            {
                return;
            }

            self.$elem.each(function()
            {
                // Get the flex container
                var $container = $(this).parents('.flex-row'),
                    height;

                if ($container[0])
                {
                    height = $container.height();
                    $(this).css('height', height + 'px');
                }
            });



        }, 100);

    }

},
{
    defaults: {
    }
});
