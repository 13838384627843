Core.MasterSlider = Garnish.Base.extend(
{
    init: function(settings)
    {
        var slider = new MasterSlider();
        slider.setup('masterslider', {
            width: 1024,
            height: 580,
            space: 1,
            fullwidth: true,
            centerControls: false,
            //speed: 18,
            loop: true,
            autoplay: true
        });
        //slider.control('arrows');
        slider.control('timebar', { insertTo: '#masterslider' });
        slider.control('bullets', { autohide: false });
    }
},
{
    defaults: {}
});
