Core.ColumnEqualHeight = Garnish.Base.extend(
{
    $container: $('.column-equal-height'),

    init: function(settings)
    {
        Core.debug && console.log('Core.ColumnEqualHeight.init() invoked');

        if (! this.$container[0])
        {
            return;
        }

        this.setSettings(settings, Core.ColumnEqualHeight.defaults);

        this.addListener(Garnish.$scrollContainer, 'resize', 'resize');

        this.resize();
    },

    resize: function()
    {
        this.$container.each(function()
        {
            var newHeight = 0,
                $columns = $(this).find('.basic-color-box');

            if (! $columns[0])
            {
                return;
            }

            // reset the column height
            $columns.css('height', 'auto');

            $columns.each(function()
            {
                var columnHeight = $(this).height();

                if (columnHeight > newHeight)
                {
                    newHeight = columnHeight;
                }
            });

            $columns.css('height', newHeight + 'px');
        });
    }

},
{
    defaults: {
    }
});
