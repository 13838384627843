Core.Clickable = Garnish.Base.extend(
{
    $elem: $('.clickable'),

    init: function(settings)
    {
        Core.debug && console.log('Core.Clickable.init() invoked');

        this.setSettings(settings, Core.Clickable.defaults);

        if (this.$elem[0])
        {
            this.addListener(this.$elem, 'click', 'onClick');
        }
    },

    onClick: function(e)
    {
        var $this = $(e.currentTarget),
            href = $this.data('href');

        window.location.replace(href);
    }
},
{
    defaults: {
    }
});
