Core.HeaderSearch = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.HeaderSearch.init() invoked');

        this.setSettings(settings, Core.StickyHeader.defaults);

        var $container = $('#basic-search-header'),
            searchEl = $container.find('.search-input'),
            searchSubmit = searchEl.find('button'),
            $searchForm = $container.find('form');

        Garnish.$doc.on('click', function(e)
        {
            if ($(e.target).closest($container).length === 0)
            {
                searchEl.removeClass('active');

                setTimeout(function()
                {
                    searchEl.hide();
                }, 250);
            }
        });

        // Register button action
        $('#basic-search-header-open').on('click', function(e)
        {
            e.preventDefault();

            searchEl.show();

            setTimeout(function ()
            {
                searchEl.addClass('active');
            }, 50);

            setTimeout(function ()
            {
                searchEl.find('input').focus();
            }, 250);
        });

        // Validate the search input: q is required
        $searchForm.validate({
            rules: {
                q: {
                    required: true
                }
            },
            // errorPlacement: function (error, element)
            // {
            //
            // },
            highlight: function (element) {
                $(element)
                    .closest(".control-group")
                    .removeClass("success")
                    .addClass("error");
            },
            success: function (element) {
                $(element)
                    .closest(".control-group")
                    .removeClass("error")
                    .addClass("success");
            }
        });

        searchSubmit.on("click", function()
        {
            $searchForm.submit();
        });
    }
},
{
    defaults: {}
});
