Core.LanguageSwitch = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.LanguageSwitch.init() invoked');

        this.setSettings(settings, Core.LanguageSwitch.defaults);

        this.addListener($(this.settings.languageSwitchMarker), 'change', 'onChange');
    },

    onChange: function(e)
    {
        var $this = $(e.currentTarget),
            selectedValue = $this.val(),
            action = null;

        $this.find('option').each(function()
        {
            var $option = $(this);

            console.log('Option value', $option.text());

            if ($option.text() === selectedValue)
            {
                action = $option.data('action');
            }
        });

        if (action)
        {
            window.location.replace(action);
        }
    }

},
{
    defaults: {
        'languageSwitchMarker': '#basic-language-selector'
    }
});
