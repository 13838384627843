/** global: Core */
/** global: Garnish */

$.extend(Core,
    {
        // define containers
        $pageWrapper: $('.ccm-page'),

        headerHeight: 60,

        initialized: false,

        debug: false,

        breakPoints: {
            'sm' : 768,
            'md' : 992,
            'lg' : 1200
        },

        currentBreakPoint: 'xs',

        initialize: function()
        {
            if (this.initialized)
            {
                return;
            }

            this.initialized = true;

            new Core.BreakPoints;
            new Core.StickyHeader({
                'scrollPoints': {
                    'xs': 100,
                    'md': 221
                }
            });
            new Core.MasterSlider;
            new Core.HeaderSearch;
            new Core.Clickable;
            new Core.BackToTop({
                'scrollPosition' : 450
            });
            new Core.LanguageSwitch;
            new Core.SlickSlider;
            new Core.FlexFill;
            new Core.ColumnEqualHeight;
        }
    }
);

// -------------------------------------------
//  Custom jQuery plugins
// -------------------------------------------
$.extend($.fn, {

    dummy: function()
    {
        return this.each(function() {
            var $elem = $(this);
        });
    }

});

Garnish.$doc.ready(function()
{
    Core.initialize();
});
