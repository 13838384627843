Core.SlickSlider = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.SlickSlider.init() invoked');

        var $slider = $('.basic-slider');

        if (! $slider[0])
        {
            return;
        }

        this.setSettings(settings, Core.SlickSlider.defaults);

        var config = $.extend({}, this.settings, $slider.data('slider-options'));

        $slider
            .on('init', function(event, slick)
            {
                var $firstSlide = $(slick.$slides[0]);
                $firstSlide.find('video')[0] && $firstSlide.find('video')[0].play();

                $slider.removeClass('slider-hide');
            })
            .slick(config);

        // On before slide change
        $slider
            .on('beforeChange', function(event, slick, currentSlide, nextSlide)
            {
                var $currentSlide = $(slick.$slides[currentSlide]),
                    $nextSlide = $(slick.$slides[nextSlide]),
                    $video = $nextSlide.find('video');

                // Start playing the video in the slide that is now coming into view
                if ($video[0])
                {
                    $video[0].play();
                }

                // Pause the video in the slide that is going away
                $video = $currentSlide.find('video');
                if ($video[0])
                {
                    $video[0].pause();
                }
            });

    }
},
{
    defaults: {
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        pauseOnFocus: true,
        pauseOnHover: true,
        duration: 10000
    }
});
