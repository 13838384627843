Core.BreakPoints = Garnish.Base.extend(
{
    init: function(settings)
    {
        Core.debug && console.log('Core.BreakPoints.init() invoked');

        this.setSettings(settings, Core.BreakPoints.defaults);

        this.addListener(Garnish.$scrollContainer, 'resize', 'updateBreakPoint');

        this.updateBreakPoint();
    },

    updateBreakPoint: function()
    {
        var viewPort = Garnish.$scrollContainer.width(),
            prevBreakPoint = 'xs';

        for (var key in Core.breakPoints)
        {
            if (! Core.breakPoints.hasOwnProperty(key))
            {
                continue;
            }

            var value = Core.breakPoints[key];

            if (viewPort < value)
            {
                Core.currentBreakPoint = prevBreakPoint;
                break;
            }

            prevBreakPoint = key;
            Core.currentBreakPoint = key;
        }

        Core.debug && console.log('Current bootstrap breakpoint:', Core.currentBreakPoint);
    }
},
{
    defaults: {}
});
