Core.StickyHeader = Garnish.Base.extend(
{
    $header: $('.basic-header-fixed'),
    $headerTop: $('.basic-header-top'),
    $headerContainer: $('.basic-header-container'),
    $body: $('body'),
    headerHeight: 0,
    headerTopHeight: 0,

    init: function(settings)
    {
        Core.debug && console.log('Core.StickyHeader.init() invoked');

        if ($('#ccm-toolbar')[0])
        {
            return;
        }

        if (this.$body.hasClass('mobile-menu-open'))
        {
            return;
        }

        if (! this.$header[0])
        {
            return;
        }

        this.setSettings(settings, Core.StickyHeader.defaults);

        this.headerHeight    = this.$header[0] ? this.$header.outerHeight() : 0;
        this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;

        this.addListener(Garnish.$scrollContainer, 'scroll', 'onScroll');
        this.addListener(Garnish.$scrollContainer, 'resize', 'updateHeader');
    },

    onScroll: function(e)
    {
        if (this.$body.hasClass('mobile-menu-open'))
        {
            return;
        }

        var $this = $(e.currentTarget),
            scrollTop = $this.scrollTop(),
            width = $this.width(),
            scrollPoint = (null !== this.getScrollPoint()) ? this.getScrollPoint() : (this.headerTopHeight + this.headerHeight);

        if (scrollTop > scrollPoint && width > 0)
        {
            Garnish.$bod.addClass('basic-fixed-header-on');
            this.$header.addClass('x-animated x-fadeInDown');
            this.$headerContainer.css('paddingBottom', this.headerHeight + 'px');
        }
        else
        {
            Garnish.$bod.removeClass('basic-fixed-header-on');
            this.$header.removeClass('x-animated x-fadeInDown');
            this.$headerContainer.css('paddingBottom', 0);
        }
    },

    updateHeader: function(e)
    {
        var $this = $(e.currentTarget), scrollPoint;

        if (this.$body.hasClass('mobile-menu-open'))
        {
            return;
        }

        if ($this.width() < 768)
        {
            return;
        }

        scrollPoint = (null !== this.getScrollPoint()) ? this.getScrollPoint() : (this.headerTopHeight + this.headerHeight);
        scrollPoint = scrollPoint - 5;

        this.headerHeight = this.$header[0] ? this.$header.outerHeight() : 0;
        this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;

        if ($this.scrollTop() < scrollPoint)
        {
            this.headerHeight = this.$header[0] ? this.$header.outerHeight() : 0;
            this.headerTopHeight = this.$headerTop[0] ? this.$headerTop.outerHeight() : 0;
        }
    },

    getScrollPoint: function ()
    {
        var breakPoints = Core.breakPoints,
            scrollPoints = this.settings.scrollPoints,
            currentBreakPoint = Core.currentBreakPoint,
            scrollPoint,
            value = 0;

        if (null === scrollPoints)
        {
            return null;
        }

        for (var breakPoint in breakPoints)
        {
            if (! breakPoints.hasOwnProperty(breakPoint)) continue;

            if (scrollPoints.hasOwnProperty(breakPoint))
            {
                value = scrollPoints[breakPoint];
            }

            scrollPoint = value;

            if (breakPoint === currentBreakPoint)
            {
                break;
            }
        }

        return scrollPoint;
    }

},
{
    defaults: {
        'scrollPoints': null
    }
});
